import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://49f16e2df94c662490082bb4431a986d@o4506249448194048.ingest.sentry.io/4506293395456000",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(
            {
                maskAllText: false,
                blockAllMedia: false
            }),
        Sentry.feedbackIntegration({
            colorScheme: "system",
            isNameRequired: true,
            isEmailRequired: true,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});